@import "./theme.scss";

@font-face {
    font-family: poppinBlack;
    src: url("./../../assets/fonts/Poppins/Poppins-Black.ttf");
}
@font-face {
  font-family: poppinMedium;
  src: url("./../../assets/fonts/Poppins/Poppins-Medium.ttf");
}
@font-face {
    font-family: poppinReg;
    src: url("./../../assets/fonts/Poppins/Poppins-Regular.ttf");
}
@font-face {
    font-family: poppinLight;
    src: url("./../../assets/fonts/Poppins/Poppins-Light.ttf");
}
@font-face {
    font-family: poppinBold;
    src: url("./../../assets/fonts/Poppins/Poppins-Bold.ttf");
}
@font-face {
    font-family: openSansReg;
    src: url('./../../assets/fonts/open_sans/OpenSans-Regular.ttf');
}
@font-face {
    font-family: openSansSemiBold;
    src: url('./../../assets/fonts/open_sans/OpenSans-SemiBold.ttf');
}
@font-face {
    font-family: openSansBold;
    src: url('./../../assets/fonts/open_sans/OpenSans-Bold.ttf');
}
@font-face {
    font-family: openSansExtraBold;
    src: url('./../../assets/fonts/open_sans/OpenSans-ExtraBold.ttf');
}
@font-face {
    font-family: openSansLight;
    src: url('./../../assets/fonts/open_sans/OpenSans-Light.ttf');
}
@font-face {
    font-family: openSansMedium;
    src: url('./../../assets/fonts/open_sans/OpenSans-Medium.ttf');
}
.cursor-pointer {
    cursor: pointer;
  }
  
  /* width */
  ::-webkit-scrollbar {
    width: 0.1px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px $white;
    border-radius: 10px;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: $grey-text;
    border-radius: 10px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: $white;
  }
  
  ::-webkit-scrollbar {
    display: none;
  }

  button:disabled {
    background-color: $grey-text !important;
    border-color: $grey-text !important;
    color: $white !important;
  }
  
  .mat-grid-tile{
    border: 1px solid grey;
  }
  .mat-grid-list{
    // height: calc(540px) !important;
    // overflow-y: scroll !important;
    background: white !important;
  }
  .action-headertab-country{
    .mat-form-field-wrapper{
      height: 50px;
      padding: 0px !important;
      margin: 0px !important;
    }
  }
  button{
    cursor: pointer;
  }
  .slick-dots {
    position: absolute !important;
    // margin-top: 15px !important;
    bottom: 0px !important;
  }
  .slick-dotted.slick-slider{
    margin-bottom: 0px !important;
  }
  .slick-dots li.slick-active button:before{
    color: white !important;
  }
  .question-answers{
    .mat-expansion-panel:not([class*=mat-elevation-z]),
    .mat-accordion .mat-expansion-panel:first-of-type{
      box-shadow: none;
      border-bottom: 1px solid gray;
      border-bottom-right-radius: 0px !important;
      border-bottom-left-radius: 0px !important;
    }
  }